const View = ({ color = "#414241", ...props }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.70175 24V13.0525H0V8.78601H3.70175V5.63961C3.70175 1.98824 5.94259 0 9.21549 0C10.7832 0 12.1307 0.116165 12.5233 0.168087V3.98398L10.2534 3.98501C8.4734 3.98501 8.12875 4.82679 8.12875 6.06206V8.78601H12.3739L11.8211 13.0525H8.12874V24H3.70175Z"
        fill={color}
      />
    </svg>
  );
};
export default View;
