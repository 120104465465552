"use client";
import Image from "@components/common/Image";
import Link from "@components/common/Link";
import styles from "./CardVertical.module.scss";
import cx from "classnames";
import { get } from "lodash";
import { useRouteContext } from "@providers/RouteProvider";
import { formatDateDisplay, timeSince } from "@utils/dateFormatter";
const View = ({
  title,
  description,
  image,
  slug,
  category,
  createdAt,
  updatedAt,
  publishedAt,
}: any) => {
  const { router } = useRouteContext();

  return (
    <Link className="card" href={router.toUrl("baiViet", { slug })}>
      <article className={styles.section}>
        <div className={styles.imageBox}>
          {get(image, "src") === "" ? (
            <div className={styles.imageBg}>
              <span className={styles.title}>{title}</span>
            </div>
          ) : (
            <Image
              {...image}
              wrapperProps={{ className: styles.image }}
              scale="1.05"
              speed="-0.3"
            />
          )}
        </div>
        <div className={cx(styles.category, "category")}>
          {get(category, "name")} | {timeSince(publishedAt)}
        </div>
        <h3 className={cx("text-ellipsis-2", styles.title)}>{title}</h3>
        <p className={cx("text-ellipsis-2", styles.description)}>
          {description}
        </p>
      </article>
    </Link>
  );
};
export default View;
