"use client";
import React from "react";
import { AppWrapperProps } from "@type/provider/Wrapper";
import RefProvider from "./RefProvider";
import PageProvider from "./PageProvider";
import RouteProvider from "./RouteProvider";
import StrapiProvider from "./StrapiProvider";
import ThemeProvider from "./ThemeProvider";

export default function Wrapper({ children, ...props }: AppWrapperProps) {
  const value = [
    RefProvider,
    PageProvider,
    RouteProvider,
    StrapiProvider,
    ThemeProvider,
    // ConfigProvider,
    // ApolloProvider,
  ];
  return value.reduceRight((acc, Component: any) => {
    return React.createElement(Component, props, acc);
  }, children);
}
