import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ehphuong/components/common/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ehphuong/components/common/Header/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ehphuong/components/Home/BannerWorkSuperSmart/Banner.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ehphuong/components/Home/FeaturePosts/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ehphuong/components/Home/QueueMessage/QueueMessage.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ehphuong/providers/LocomotiveProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ehphuong/providers/WrapperProvider.tsx");
