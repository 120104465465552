const View = ({ color = "#414241", ...props }: any) => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9949 0C6.14676 0 0.637329 5.48312 0.637329 12.2986C0.637329 17.7304 4.19014 22.3424 9.08169 23.9822C9.69956 24.0847 9.90553 23.726 9.90553 23.3673C9.90553 23.0598 9.90553 22.2912 9.90553 21.2663C6.4557 22.035 5.73484 19.6265 5.73484 19.6265C5.16845 18.1916 4.34461 17.8329 4.34461 17.8329C3.21183 17.0643 4.44759 17.0643 4.44759 17.0643C5.68335 17.1668 6.35272 18.3454 6.35272 18.3454C7.43401 20.2414 9.23616 19.6777 9.95702 19.3703C10.06 18.5504 10.3689 18.0379 10.7294 17.7305C8.0004 17.423 5.11696 16.3469 5.11696 11.6324C5.11696 10.3001 5.58037 9.17269 6.40421 8.35278C6.30123 8.04532 5.83782 6.81546 6.50719 5.07316C6.50719 5.07316 7.53699 4.7657 9.90553 6.35427C10.8838 6.09804 11.9651 5.94431 12.9949 5.94431C14.0247 5.94431 15.106 6.09804 16.0843 6.35427C18.4529 4.7657 19.4827 5.07316 19.4827 5.07316C20.152 6.76422 19.7401 7.99408 19.5856 8.35278C20.358 9.22393 20.8729 10.3001 20.8729 11.6324C20.8729 16.3469 17.9895 17.3717 15.209 17.6792C15.6724 18.0379 16.0328 18.8066 16.0328 19.9339C16.0328 21.5738 16.0328 22.9061 16.0328 23.3161C16.0328 23.6235 16.2388 24.0335 16.9082 23.931C21.7997 22.3424 25.3525 17.7304 25.3525 12.2986C25.3525 5.48312 19.8431 0 12.9949 0Z"
        fill={color}
      />
    </svg>
  );
};
export default View;
