import "dayjs/locale/vi";
import dayjs from "dayjs";

dayjs.locale("vi");
import { get, has, compact, join } from "@utils/lodash";
export const formatDateDisplay = (date: string | Date, format?: string) => {
  return !!date ? dayjs(date, format).format(`DD MMMM, YYYY`) : "";
};

export const timeSince = (date: string | Date, format?: string) => {
  const today: any = dayjs();
  const _date: any = dayjs(date, format);
  const seconds = Math.floor((today - _date) / 1000);

  // var interval = seconds / 31536000;

  // if (interval > 1) {
  //   return Math.floor(interval) + " years";
  // }
  let interval = seconds / 2592000;
  if (interval > 1) {
    return _date.format("DD MMMM, YYYY");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " ngày trước";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " giờ trước";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " phút trước";
  }
  return Math.floor(seconds) + " giây trước";
};
export function formatHMS(seconds: number, opts: object) {
  const showHours = has(opts, "showHours") ? get(opts, "showHours") : true;
  const showMinutes = has(opts, "showMinutes")
    ? get(opts, "showMinutes")
    : true;
  const showSeconds = has(opts, "showSeconds")
    ? get(opts, "showSeconds")
    : true;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
  const result = compact([
    showHours ? formattedHours : "",
    showMinutes ? formattedMinutes : "",
    showSeconds ? formattedSeconds : "",
  ]);
  return join(result, ":");
}

// Example usage:
