"use client";
import React from "react";
import Facebook from "@components/common/Icons/Facebook";
import Instagram from "@components/common/Icons/Instagram";
import Github from "@components/common/Icons/Github";
// import Logo from "@components/common/Logo";
// import ClientWrapper from "@containers/ClientWrapper";
import { useRouteContext } from "@providers/RouteProvider";
import styles from "./Footer.module.scss";
import cx from "classnames";

const Footer = (props: any) => {
  const { router } = useRouteContext();
  return (
    // <ClientWrapper>
    <footer
      data-scroll-section
      className={cx(
        "inner flex items-center justify-between py-8",
        styles.footer
      )}
    >
      {/* <div className="mr-4">
          <Logo radius={20} borderWidth={2} />
        </div> */}
      <div className={styles.codedBy}>
        © 2023 | Coded by <span className={styles.author}>Phuong Vo</span>.
      </div>
      <div className={styles.group}>
        <Facebook
          className="cursor-pointer"
          onClick={() => window.open(router.toUrl("facebook"), "_blank")}
        />
        <Instagram
          className="cursor-pointer"
          onClick={() => window.open(router.toUrl("instagram"), "_blank")}
        />
        <Github
          className="cursor-pointer"
          onClick={() => window.open(router.toUrl("github"), "_blank")}
        />
      </div>
    </footer>
    // </ClientWrapper>
  );
};
export default Footer;
