"use client";

import cx from "classnames";
import styles from "./FeaturePosts.module.scss";
import CardVertical from "@components/common/CardVertical";
import { usePageContext } from "@providers/PageProvider";
import { map, get } from "lodash";
import helper from "@utils/helper";

const View = () => {
  const { pageData } = usePageContext();
  const data = get(pageData, "articles");
  const articles = map(data, helper.formatArticle);
  //
  return (
    <section data-scroll-section className={cx("inner", styles.section)}>
      <div className="label-title">Blogs</div>
      <div className={styles.box}>
        {map(articles, (item, index) => (
          <CardVertical key={`card_${index}`} {...item} />
        ))}
      </div>
    </section>
  );
};
export default View;
